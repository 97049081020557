@mixin dropShadowPrimary(){
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

@mixin page {
  text-align: center;
  text-align: -webkit-center;
  text-align: -moz-center;
  width: 100%;
  height: 100%;
  padding: 16px 16px 60px 16px;
  box-sizing: border-box;

  & h1, h2, h3, h4, h5, h6 {
    font-family: 'Roboto', sans-serif;
  }
}

@mixin pageButtonGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 2.13em;
  > * {
    margin-bottom: 1.8em;
  }
}

@mixin question {
  font-size: 32px;
  font-weight: 600;
}
