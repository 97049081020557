$color-primary-green: #02A090;
$color-secondary-green: #01A090;
$color-primary-white: #ffffff;
$color-progressbar: #abdfdb;
$color-progressbar-progress: #01A090;
$color-button-orange: rgb(247,160,145);

$color-primary-alert-success: #b8e4e0;
$color-secondary-alert-success: #ccece9;
$color-text-alert-success: #01534b;
$color-primary-error: #f5c6cb;
$color-secondary-error: #f8d7da;
$color-text-error: #721c24;


$fi-path: 'foundation-icon-fonts';