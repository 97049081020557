@import './global-styles/variables';
@import './global-styles/mixins';

.App {
  width:100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__page-wrapper {
    padding: 40px 0 0 0;
    width: 100%;
    height: 100%;
  }

  &__disclaimer-popup {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
  }

  &-overlay {
    background-color: rgba(0, 0, 0, 0.1);
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__disclaimer-container {
    @include dropShadowPrimary;
    width: 100%;
    max-width: 600px;
    margin: auto;
    margin-left: 16px;
    margin-right: 16px;
    background-color: white;
    border: solid 1px rgba(200, 200, 200, 0.5);
    box-shadow: 4px 4px 4px rgba(100, 100, 100, .1);

    &.App-service {
      max-width: 900px;
    }
  }

  &__disclaimer-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 20px;
    font-weight: bold;
    padding: 20px;

    &.App-service {
      font-size: 28px;
      padding: 40px;
    }

    & button {
      cursor: pointer;
      font-size: 20px;
      background-color: transparent;
    }
  }

  &__disclaimer-divider {
    height: 1px;
    background-color: rgba(200, 200, 200, 0.5);
  }

  &__disclaimer {
    padding: 20px;
    font-size: 16px;
    line-height: 1.5;

    &.App-service {
      font-size: 24px;
      padding: 40px;
    }
  }
}