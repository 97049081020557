@import '../../global-styles/_variables';

.AppInput {
  position: relative;

  & input {
    font-size: 16px;
    border: solid $color-primary-green;
    border-radius: 4px;
    padding: 4px;

    &:invalid {
      border-color: $color-primary-error;

      & + span {
        display: block;
        position: absolute;
        padding: 4px;
        font-size: 14px;
        color: $color-text-error;
        bottom: -24px;
      }
    }
  }

  & span {
    display: none;
  }
}