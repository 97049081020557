@import '../../global-styles/_variables.scss';

.AppDialogWrapperBackground {
  position: absolute;
  background-color: $color-primary-white;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index:10;
  opacity: .6;
  filter: brightness(20%);

  &.hidden {
    display: none;
  }

  &.visible {
    display: block;
  }

}
.AppDialogWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: flex-start;
  justify-content: center;
  z-index: 11;

  &.hidden {
    display: none;
  }

  &.visible {
    display: flex;
  }

}
.AppDialog {
  margin: 160px 16px 0 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px 40px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: $color-primary-white;
  z-index: 12;
  max-width: 600px;

  &.App-service {
    max-width: 900px;
    padding: 80px;
  }

  &__heading {
    font-size: 31.5px;

    &.App-service {
      font-size: 36px;
    }
  }

  &__info {
    font-size: 21.5px;
    margin: 32px 0;

    &.App-service {
      font-size: 24px;
    }
  }

  &__controls {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

}